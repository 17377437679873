import mapValues from 'lodash/mapValues'
import every from 'lodash/every'
import each from 'lodash/each'

export default function validateForm(params) {

  const options = {
    form: '#contactForm',
    action: '/contacts/create',
    required: {}, // {Name: Selector}
    customValidate: () => true,
    customParams: () => ({}),
    ...params
  }

  document.addEventListener('DOMContentLoaded', function () {
    const emailRegex = /\S+@\S+\.\S+/
    const form = document.querySelector(options.form)
    const loading = form.querySelector('button[type=submit] .loading')
    const success = document.getElementById('success')
    const error = document.getElementById('error')
    const errorTemplate = error.textContent
    const name = document.getElementById('name')
    const email = document.getElementById('email')
    const nickname = document.getElementById('nickname')
    const check = document.getElementById('check')

    const requiredElements = mapValues(options.required, q => document.querySelector(q))

    function showError() {
      success.classList.toggle('d-none', true)
      error.textContent = name ? errorTemplate.replace(/\{0}/, name.value) : errorTemplate
      error.classList.toggle('d-none', false)
    }

    function showSuccess() {
      success.classList.toggle('d-none', false)
      error.classList.toggle('d-none', true)
    }

    form.addEventListener('submit', function (event) {
      event.preventDefault()
      if (!loading.classList.contains('d-none')) return

      if (name) name.parentNode.querySelector('.help-required').classList.toggle('d-none', name.value)
      if (email) {
        email.parentNode.querySelector('.help-required')?.classList?.toggle('d-none', email.value)
        email.parentNode.querySelector('.help-email')?.classList?.toggle('d-none', !email.value || emailRegex.test(email.value))
      }
      if (check) check.parentNode.querySelector('.help-required')?.classList?.toggle('d-none', check.checked)

      each(requiredElements, e => e.parentNode.querySelector('.help-required').classList.toggle('d-none', e.value))

      const nameValid = !name || name && name.value
      const emailValid = !email || email && email.value && emailRegex.test(email.value)
      const checkValid = !check || check && check.checked
      const defaultFieldsValid = nameValid && emailValid && checkValid
      const requiredFieldsValid = every(requiredElements, e => e.value)
      const customValid = options.customValidate()
      if (defaultFieldsValid && requiredFieldsValid && customValid) {
        const params = {
          name: name?.value,
          email: email?.value,
          nickname: nickname.value,
          ...mapValues(requiredElements, e => e.value),
          ...options.customParams()
        }

        loading.classList.remove('d-none')

        fetch(form.action, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        }).then(function (response) {
          response.ok ? showSuccess() : showError()
        }).catch(showError).finally(() => loading.classList.add('d-none'))
      }
    })
  })
}